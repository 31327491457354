//= require tinymce

import $ from 'jquery'
import 'select2'
import 'bootstrap/dist/js/bootstrap.bundle'
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
require("jquery")
import 'swiper/swiper-bundle.css'

require("./phones")
require("./repair_items")
require("./posts")
require("./swipers")
require("./flash_alerts")
require("./search")
require("./forms_validate")

Rails.start()
Turbolinks.start()
ActiveStorage.start()

$(document).on('turbolinks:load', function() { 
  $("#track-close").click(function() { 
    $(".block-status-repair").removeClass('active');
  });
});

$(document).on('turbolinks:load', function() { 
  $('#btn-track').click(function(e) {
    e.preventDefault();
    var order_code = $('#order-code').val();
    $.ajax({
      type: 'POST',
      url: 'get_status_code',   
      data: { order_code: order_code }, // change 'code' to 'order_code'
      success: function() {
        $(".block-status-repair").addClass('active');
      }
    });
  });
});