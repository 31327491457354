// import Swiper JS
import Swiper from 'swiper';
// import Swiper styles
import SwiperCore, { Mousewheel, Navigation, Pagination } from 'swiper';
SwiperCore.use([Mousewheel, Navigation, Pagination]);

$(document).on('turbolinks:load', function() {
  const swiper = new Swiper(".repairs-swiper", {
    freeMode: true,
    breakpoints: {
      1000: {
          slidesPerView: 7,
      },
      800: {
          slidesPerView: 5.5,
      },
      650: {
        slidesPerView: 4.4,
      },
      500: {
          slidesPerView: 3.5,
      },
      200: {
        slidesPerView: 2.5,
      },
    }
  });
  const lolswiper = new Swiper(".advantages-swiper", {
    freeMode: true,
    breakpoints: {
      1200: {
        slidesPerView: 3,
        spaceBetween: 40,
      },
      900: {
        slidesPerView: 2.3,
        spaceBetween: 35,
      },
      700: {
        slidesPerView: 1.8,
        spaceBetween: 35,
      },
      550: {
        slidesPerView: 1.4,
        spaceBetween: 25,
      },
      200: {
        slidesPerView: 1.2,
        spaceBetween: 12,
      },
    }
  });
  const logosswiper = new Swiper(".logos-swiper", {
    freeMode: true,
    breakpoints: {
      1000: {
        slidesPerView: 1,
      },
      750: {
        slidesPerView: 0.8,
      },
      600: {
        slidesPerView: 0.35,
      },
      500: {
        slidesPerView: 0.3,
      },
      300: {
        slidesPerView: 0.2,
      },
    }
  });
  const reviewsswiper = new Swiper(".reviews-swiper", {
    freeMode: true,
    breakpoints: {
      1000: {
        spaceBetween: 20,
        slidesPerView: 1,
      },
      626: {
        slidesPerView: 1,
      },
      550: {
        slidesPerView: 0.6,
      },
      450: {
        slidesPerView: 0.4,
      },
      300: {
        slidesPerView: 0.35,
      },
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });
  const addswiper = new Swiper(".adds-swiper", {
    breakpoints: {
      550: {
        slidesPerView: 1,
        freeMode: false,
        spaceBetween: 20,
      },
      450: {
        freeMode: true,
        slidesPerView: 1,
        spaceBetween: 20,
      },
      300: {
        slidesPerView: 1.05,
        freeMode: true,
        spaceBetween: 20,
      },
    },
    pagination: {
      el: ".swiper-pagination",
    }
  });
  const newsswiper = new Swiper(".news-swiper", {
    breakpoints: {
      500: {
        slidesPerView: 'auto',
        freeMode: false,
        spaceBetween: 20,
      },
      300: {
        slidesPerView: 1.2,
        spaceBetween: 20,
        freeMode: true,
      },
    },
    pagination: {
      el: ".swiper-pagination",
    }
  });
  const phonesswiper = new Swiper(".swiper-phones", {
    freeMode: true,
    breakpoints: {
      1000: {
        spaceBetween: 20,
        slidesPerView: 1,
      },
      626: {
        slidesPerView: 1,
      },
      550: {
        slidesPerView: 0.6,
      },
      450: {
        slidesPerView: 0.4,
      },
      300: {
        slidesPerView: 0.35,
      },
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });
  const swiperbrands = new Swiper(".swiper-brands", {
    freeMode: true,

    breakpoints: {
      900: {
        slidesPerView: 4,
      },
      600: {
        slidesPerView: 3,
        slidesPerColumn: 2,
        slidesPerGroup: 2,
        grid: {
          rows: 2,
        },
      },
      500: {
        slidesPerView: 2.5,
        slidesPerColumn: 3,
        slidesPerGroup: 3,
      },
      420: {
        slidesPerView: 2.1,
        slidesPerColumn: 3,
        slidesPerGroup: 3,
      },
      300: {
        slidesPerView: 1.7,
        slidesPerColumn: 3,
        slidesPerGroup: 3,
      }
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });
  const iphonesswiper = new Swiper(".swiper-iphones", {
    freeMode: true,
    speed: 300,
    breakpoints: {
      900: {
        spaceBetween: 20,
        slidesPerView: 7,
      },
      770: {
        slidesPerView: 6,
      },
      650: {
        slidesPerView: 5,
        spaceBetween: 15,
      },
      480: {
        slidesPerView: 4,
      },
      300: {
        slidesPerView: 3,
        spaceBetween: 10,
      },
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });

  const watchesswiper = new Swiper(".swiper-watches", {
    freeMode: true,
    speed: 300,
    breakpoints: {
      900: {
        spaceBetween: 20,
        slidesPerView: 5,
      },
      600: {
        slidesPerView: 4,
        spaceBetween: 15,
      },
      450: {
        slidesPerView: 3,
        spaceBetween: 10,
      },
      300: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });

  const ipadsswiper = new Swiper(".swiper-ipads", {
    freeMode: true,
    speed: 300,
    breakpoints: {
      900: {
        spaceBetween: 20,
        slidesPerView: 6,
      },
      650: {
        slidesPerView: 5,
        spaceBetween: 15,
      },
      480: {
        slidesPerView: 4,
      },
      410: {
        slidesPerView: 3,
        spaceBetween: 10,
      },
      300: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });

  const macbooksswiper = new Swiper(".swiper-macbooks", {
    freeMode: true,
    speed: 300,
    breakpoints: {
      900: {
        spaceBetween: 20,
        slidesPerView: 5,
      },
      650: {
        slidesPerView: 4,
        spaceBetween: 15,
      },
      480: {
        slidesPerView: 3,
      },
      300: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });

  const airpodsswiper = new Swiper(".swiper-airpods", {
    freeMode: true,
    speed: 300,
    breakpoints: {
      900: {
        spaceBetween: 20,
        slidesPerView: 5,
      },
      650: {
        slidesPerView: 4,
        spaceBetween: 15,
      },
      480: {
        slidesPerView: 3,
      },
      300: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });
});
