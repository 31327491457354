$(document).on('turbolinks:load', function() { 
  $(".form-search").click(function() { 
    $(".form-search").addClass('active');
    $("#search-filters").addClass('active');
    $("#search-input").focus();
    $(".sticky-footer").click(function() {
      $(".form-search").removeClass("active");
      $("#search-filters").removeClass("active");
    });
  });
  $("#search-input").keyup(function () { 
    var input, filter, ul, li, a, i, txtValue, resultsCount = 0;
    input = document.getElementById("search-input");
    filter = input.value.toUpperCase();
    ul = document.getElementById("search-filters");
    li = ul.getElementsByTagName("li");
    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("a")[0];
      txtValue = a.textContent || a.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
        resultsCount++;
      } else {
        li[i].style.display = "none";
      }
    }
    var notFound = document.getElementById('not-found');
    if(resultsCount === 0) {
      notFound.style.display = "block";
    } else {
      notFound.style.display = "none";
    }
  });
});