$(document).on('turbolinks:load', function() {
  $(".repairs__item").click(function() {
    $(".repairs__item").each(function() {
      $(this).removeClass("active");
      $('.info-repair-block').removeClass('active');
    });
    $(this).toggleClass("active");
    let idItem = $(this).attr('id');
    let index = idItem.split("-")[2]; 
    $(`#repair-block-${index}`).toggleClass("active");
  });

  $(document).click(function(event) { 
    var $target = $(event.target);
    if(!$target.closest('.repairs').length && $('.repairs').is(":visible")) {
      $(".repairs__item").removeClass("active");
      $('.info-repair-block').removeClass('active');
    }        
  });
});