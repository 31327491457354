$(document).on("input", "input.contact_phone", function() {
  if (($('.contact_phone').val()).length > 9 || ($('.contact_phone').val()).length == 0) {
    $(".number_phone_warning").removeClass('active');
  } else {
    $(".number_phone_warning").addClass('active');
  }
});

$(document).on("input", "input.order_repair_phone", function() {
  if (($('.order_repair_phone').val()).length > 9 || ($('.order_repair_phone').val()).length == 0) {
    $(".number_phone_warning").removeClass('active');
  } else {
    $(".number_phone_warning").addClass('active');
  }
});

$(document).on("input", "input.order_call_phone", function() {
  if (($('.order_call_phone').val()).length > 9 || ($('.order_call_phone').val()).length == 0) {
    $(".number_phone_warning").removeClass('active');
  } else {
    $(".number_phone_warning").addClass('active');
  }
});

// Contact Form

$(document).on('turbolinks:load', function() {
  $('.validateContactform').keyup(function () {
    styleContactSubmitbutton(isContactValid());
  });

  let submit_button = document.getElementById("btn-contact");

  function styleContactSubmitbutton(isValidated) {
    if (isValidated) {
      submit_button.disabled = false
      submit_button.style.backgroundColor = "#EE4D4D", submit_button.style.color = "#fff";
    } else {
      submit_button.style.backgroundColor = "#a6aecd", submit_button.style.color = "#fff";
      submit_button.disabled = true
    }
  };

  function isContactValid() {
    if (!$('#contact_name').val()) { return }
    if (!$('#contact_message').val()) { return }
    if (($('#contact_phone').val()).length < 10) { return }

    return true;
  };
});


// Order Call Form

$(document).on('turbolinks:load', function() {
  $('.validateOrderCallform').keyup(function () {
    styleOrderCallSubmitbutton(isOrderCallValid());
  });

  function styleOrderCallSubmitbutton(isValidated) {
    let submit_button = document.getElementById("btn-order-call");

    if (isValidated) {
      submit_button.disabled = false
      submit_button.style.backgroundColor = "#EE4D4D", submit_button.style.color = "#fff";
    } else {
      submit_button.style.backgroundColor = "#a6aecd", submit_button.style.color = "#fff";
      submit_button.disabled = true
    }
  };

  function isOrderCallValid() {
    if (!$('#order_call_name').val()) { return }
    if (($('#order_call_phone').val()).length < 10) { return }

    return true;
  };
});

// Review Form

$(document).on('turbolinks:load', function() {
  $('.validateReviewform').keyup(function () {
    styleReviewSubmitbutton(isReviewValid());
  });
  
  function styleReviewSubmitbutton(isValidated) {
    let submit_button = document.getElementById("btn-review");
  
    if (isValidated) {
      submit_button.disabled = false
      submit_button.style.backgroundColor = "#EE4D4D", submit_button.style.color = "#fff";
    } else {
      submit_button.style.backgroundColor = "#a6aecd", submit_button.style.color = "#fff";
      submit_button.disabled = true
    }
  };

  function isReviewValid() {
    if (!$('#review_name').val()) { return }
    if (!$('#review_message').val()) { return }
    if (!($('#review_email').val())) { return }
  
    return true;
  };
});

// Order Repair Form

$(document).on('turbolinks:load', function() {
  $('.validateOrderRepairform').keyup(function () {
    styleOrderRepairSubmitbutton(isOrderRepairValid());
  });

  function styleOrderRepairSubmitbutton(isValidated) {
    let submit_button = document.getElementById("btn-order-repair");
  
    if (isValidated) {
      submit_button.disabled = false
      submit_button.style.backgroundColor = "#EE4D4D", submit_button.style.color = "#fff";
    } else {
      submit_button.style.backgroundColor = "#a6aecd", submit_button.style.color = "#fff";
      submit_button.disabled = true
    }
  };
  
  function isOrderRepairValid() {
    if (!$('#order_repair_name').val()) { return }
    if (!$('#order_repair_message').val()) { return }
    if (($('#order_repair_phone').val()).length < 10) { return }
  
    return true;
  };
});